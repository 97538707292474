import React, { useEffect, useState, createContext, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { initializeAnalytics, reactPlugin } from "./analytics";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import "./index.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Navbar } from "./components/Navbar";
import { initDataLinker } from "@ez/design-system-data-linker";
import { fetchAuthToken } from "./utils/helpers";
import { Home } from "./Home";
import { NotFound } from "./notfound";
import { EZ_CAPTURE_CLIENT } from "./services/client";
import { AccountInfo } from "@azure/msal-browser";
import { NoteType } from "./components/Selector";
import { IconFileLookup, TIconName } from "./utils/icons";
import { fetchRecordTypes } from "./services/api/recordTypes";
import { ResponseRecordType } from "./services/recordType";
import { fetchFileExtensions } from "./services/api/fileExtension";
import { FileExtension } from "./services/fileExtension";
import { APIContext } from "./services/APIContext";

initializeAnalytics();
export const LoggedInUserContext = createContext<AccountInfo>({} as AccountInfo);
export const AuthTokenContext = createContext<string>("");
export const FileExtensionContext = createContext<string[]>([]);
export const RecordTypeContext = createContext<NoteType[]>([]);

function App() {
  const { instance } = useMsal();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<AccountInfo>();
  const [authToken, setAuthToken] = useState("");
  const apiClient = useContext(APIContext);
  const [conversationTypeCells, setConversationTypeCells] = useState<NoteType[]>([]);
  const [allowedFileExtensions, setAllowedFileExtensions] = useState<string[]>([]);

  let activeAccount = instance.getActiveAccount();

  const getAuthTokenAsync = async () => {
    return await fetchAuthToken(instance, loginRequest);
  };

  const getBaseUrl = () => process.env.REACT_APP_API_BASE_URL!;

  useEffect(() => {
    const handleRedirect = async () => {
      await instance.handleRedirectPromise();

      try {
        if (!activeAccount) {
          await instance.loginRedirect(loginRequest);
          setLoggedIn(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchToken = async () => {
      const token = await getAuthTokenAsync();
      setAuthToken(token);
    };

    if (!isLoggedIn) handleRedirect();

    if (activeAccount) {
      setLoggedInUser(activeAccount!);
      fetchToken();
    }
    initDataLinker(getBaseUrl, getAuthTokenAsync, EZ_CAPTURE_CLIENT);
  }, [isLoggedIn]);

  useEffect(() => {
    const icons: TIconName[] = [
      "cardsDark",
      "listDark",
      "note",
      "personOutline",
      "whiskeyGlassIce",
      "handshakeSimple",
      "star",
      "commentLines",
      "bookSparkles",
      "books",
      "building",
    ];

    const fetchData = async () => {
      fetchRecordTypes(apiClient, authToken).then((response) => {
        // temporary exception - will be removed by the hardcoded-ruleset refactor
        const exceptions = ["Letter of proposal", "Final offer letter"];

        const typesToUse = response.filter((item: ResponseRecordType) => {
          return !exceptions.includes(item.name);
        });

        const conversationTypeCells: NoteType[] = typesToUse.map(
          (item: ResponseRecordType, i: number): NoteType => {
            const icon = IconFileLookup[icons[i]];
            return {
              id: item.id.toString(),
              label: item.name,
              icon: icon,
            };
          }
        );

        setConversationTypeCells(conversationTypeCells);
      });

      fetchFileExtensions(apiClient, authToken).then((response) => {
        const allowedFileExtensions: string[] = response.map(
          (item: FileExtension): string => {
            return item.extension;
          }
        );
        setAllowedFileExtensions(allowedFileExtensions);
      });
    };

    if (authToken) {
      fetchData();
    }
  }, [authToken, apiClient, instance]);

  const isInitDataReady =
    conversationTypeCells.length > 0 && allowedFileExtensions.length > 0;

  return (
    <>
      {isInitDataReady && (
        <AuthTokenContext.Provider value={authToken}>
          <LoggedInUserContext.Provider value={loggedInUser!}>
            <FileExtensionContext.Provider value={allowedFileExtensions}>
              <RecordTypeContext.Provider value={conversationTypeCells}>
                <AppInsightsContext.Provider value={reactPlugin}>
                  <BrowserRouter>
                    <Navbar />

                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </BrowserRouter>
                </AppInsightsContext.Provider>
              </RecordTypeContext.Provider>
            </FileExtensionContext.Provider>
          </LoggedInUserContext.Provider>
        </AuthTokenContext.Provider>
      )}
    </>
  );
}

export default App;
